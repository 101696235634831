:root {
  --primary: #db147f;
  --light-primary: #ffc5f2;
  --secondary: #32a4fc;
  --loading-bg: #2c3e51cc;
  --bg-primary: #06aeba;
  --bg-warning: #f8b51d;
  --bg-error: #ed1558;
  --bg-content: #fff;
  --bg-container: #f7f8f9;
  --bg-input: #efefef;
  --bg-search-input: #f3f3f3;
  --bg-light: #f9f9f9;
  --bg-black: #000;
  --bg-gray: #eeeeee;
  --bg-green: #1fada8;
  --bg-light-blue: #7ac4fd;
  --bg-light-green: #56d1a1;
  --bg-alice-blue: #eef4ff;

  --bg-disabled: #969696;
  --bg-header-table: #f7fafc;
  --bg-gray: #f6f6f6;
  --bg-light: #f6f6f6;
  --bg-footer: #fff;
  --bg-dark-gray: #e0e0e0;
  --bg-second-light: #f4f4f4;
  --bg-second-light: #f4f4f4;
  --bg-pastel-blue: #e9f4ff;
  --bg-pastel-pink: #fff0fb;
  --bg-pastel-green: #ecf9f8;
  --bg-grey-l1: #f7f8f9;
  --bg-pastel-green-l100: #f0fbfb;

  --text-primary: #000000;
  --text-default: #1f2933;
  --text-light-primary: #3e4c59;
  --text-secondary: #5c595a;
  --text-tab-title: #7f817c;
  --text-disabled: #969696;
  --text-form-label: #1f2933;
  --text-header-table: #0f100d;
  --text-note: #00000034;
  --text-description: #777777;
  --text-form-icon: #41433f;
  --text-footer: #000;
  --text-placeholder: #808080;
  --text-error: #d10000;
  --text-sub-header: #454545;
  --text-white: #ffffff;
  --text-green: #2bada7;
  --text-green-white: #f0fbfb;
  --text-grey-l1: #758098;
  --text-black-l8: #141533;
  --text-gray-white: #38465c;
  --text-black-neutral: #141533;
  --text-gray-neutral-l400: #98a2b3;
  --text-information-default: #3570db;
  --text-pastel-pink-200: #fd82c3;
  --text-green-teal-900: #095b5c;
  --text-graph-yellow: #ffb912;

  --border-color: #d7d7d7;
  --border-color-light: #ebecf0;
  --border-light-gray: #e9ecf7;
  --border-radius: 8px;
  --border-radius-checkbox: 4px;
  --border-radius-card: 10px;

  --scrollbar-thumb: #b7b6c2;
  --scrollbar-track: #f0f3fa;

  --color-gray-icon: #acb2b8;

  --color-gray: #808080;
  --color-grey: #2d1f21;
  --color-grey-l3: #aba7a7;
  --color-grey-l2: #857e7f;
  --color-grey-l5: #cbd2d9;
  --color-green: #4fcea2;
  --color-brightGreen: #4cd964;
  --color-red: #e64c38;
  --color-blue: #0992d0;
  --color-orange: #f5a623;
  --color-darkOrange: #f67800;
  --color-pink: #f75d81;
  --color-limeGreen: #4fcea2;
  --color-lightGreen: #3ebac2;
  --color-blueShade: #2d7fd3;
  --color-yellow: #ffca28;
  --color-violet: #665ca7;
  --color-purple: #ac009f;
  --color-d2: #d2d2d2;
  --color-black: #000000;
  --color-45: #454545;
  --color-info: #316dde;
  --color-domain: #0ea800;
  --color-pastel-pink: #ff8ad1;
  --color-pastel-green: #105b5c;
  --color-gray-neutral: #d0d5dd;
  --color-green-neutral: #d6f5f3;
  --color-pastel-pink-l200: #fd82c3;
  --color-warning-default: #f98437;
  --color-light-blue: #ecf7fe;
  --color-light-pink: #fff4fa;
  --color-blue-300: #87c9fb;
  --avatar-bg: #f3f5f8;
  --avatar-color: #939dae;

  --shadow: 0px 2px 16px rgba(39, 40, 51, 0.12);

  --font-size-s: 12px;
  --font-size-m: 14px;
  --font-size-l: 16px;
  --font-size-xl: 20px;
  --font-size-xxl: 26px;
  --font-size-xxxl: 36px;
  --font-size-xxxxl: 51px;
  --font-size-normal: 22px;

  --font-family: Mulish, sans-serif;
  --font-title: Mulish, sans-serif;

  --pl-60: 60px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Mulish, sans-serif;
}

.responsive-wrapper {
  bottom: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.size-s {
  font-size: var(--font-size-s) !important;
}

.size-m {
  font-size: var(--font-size-m) !important;
}

.size-l {
  font-size: var(--font-size-l) !important;
  line-height: 22px;
}

.size-xl {
  font-size: var(--font-size-xl) !important;
}

.size-normal {
  font-size: var(--font-size-normal);
}

.size-xxl {
  font-size: var(--font-size-xxl) !important;
}
.size-xxxl {
  font-size: var(--font-size-xxxl) !important;
}

.font-title {
  font-family: var(--font-title) !important;
}

.link {
  position: relative;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
.link:hover {
  color: var(--primary);
}
.link:after {
  display: block;
  content: '';
  border-bottom: solid 2px var(--primary);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 100% 50%;
}
.link:hover:after {
  transform: scaleX(1);
  transform-origin: 0 50%;
}

.bolder {
  font-weight: 600;
}

.border {
  border: 1px solid #efefef;
}

video {
  clip-path: inset(1px 1px);
}
